import React from 'react'
import { Image } from '../../Images'

interface CustomIconProps {
  src: string
  alt?: string
  width?: number
  height?: number
}

const CustomIcon: React.FC<CustomIconProps> = ({ src, alt = 'icon', height = 10, width = 12 }) => {
  return <Image layout="FULL_WIDTH" src={src} alt={alt} style={{ height: height, width: width }} />
}

export default CustomIcon
