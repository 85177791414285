import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import ContactAgentForm from './contact/contactAgentForm'
import FormContactAgent from './agency/team/FormContactAgent'


const useStyles = makeStyles((theme) => ({
  contactContainer: {
    [theme.breakpoints.between('xs', 'sm')]: {
      // padding: theme.spacing(2),
    },
  },

  container: {
    flexBasis: '50%',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexBasis: '100%',
    },
    [theme.breakpoints.between('md', 'md')]: {
      flexBasis: '80%',
    },
  },
  font: {
    fontSize: theme.typography.pxToRem(12),
    marginRight: theme.spacing(1),
  },

  transparent: {
    backgroundColor: 'transparent',
    borderBottom: 'none',
    borderRight: 'none',
  },
  mb2: {
    marginBottom: '10px',
  },
  mb3: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(5),
    },
  },
  mt5: {
    marginTop: theme.spacing(4),
  },
  pr3: {
    paddingRight: theme.spacing(3),
  },
  pb2: {
    paddingBottom: '10px',
  },
  pl3: {
    paddingLeft: theme.spacing(3),
  },
  blackColor: {
    color: '#000',
  },
}))

const AgentContactForm: React.FC<any> = ({
  reference,
  emailTo,
  agent,
  country
}: {
  reference: string
  emailTo: string
  agent: any
  country: any
}) => {
  const classes = useStyles()
  const intl = useIntl()
  const emailLink = emailTo ? 'mailto:' + emailTo : '#'

  return (
    <Grid className={classes.contactContainer} container justifyContent="center">
      {/* <Grid item xs={12} className={classes.heading} container justifyContent="center">
        {intl.formatMessage({ id: 'contactAgent' })}
      </Grid> */}
      <Grid className={classes.container}>
        <ContactAgentForm agent={agent} reference={reference} country={country} />
      </Grid>
    </Grid>
  )
}

export default AgentContactForm
