import React, { useEffect, useState, useRef, useCallback, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Layout } from '../component/Layout'
import { PropertyDetails } from '../component/page_component/PropertyDetails'
import { PropertyDisplay } from '../component/page_component/PropertyDisplay'
import { PropertyExtraDetails } from '../component/page_component/PropertyExtraDetails'
import Agent from '../component/page_component/Agent'
import AgentContactForm from '../component/page_component/AgentContactForm'
import PropertySlider from '../component/page_component/PropertySlider'
import PropertyGallery from '../component/page_component/PropertyGallery'
import PropertyMap from '../component/page_component/PropertyMap'
import PropertyFooter from '../component/page_component/PropertyFooter'
import PropertyBottomBar from '../component/page_component/PropertyBottomBar'
import { useIntl } from 'gatsby-plugin-intl'
import { capitalize } from '../utils/helper'
import { STATUS_A_LOUER, STATUS_SAISONNIER } from '../utils/constants'
import Carousel from 'react-multi-carousel'
import PropertyDialog from '../component/page_component/PropertyDialog'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { getCookieValue } from '../utils/cookies'
import hreflangConfig from '../utils/hreflangConfig'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'

import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  IconButton,
  Slide,
} from '@material-ui/core'
import { PropertyBoxAnnonce } from '../component/DetailBox/PropertyBoxAnnonce'
import SeoContent from '../component/page_component/SeoContent'
import Display from '../component/page_component/Display'
import CustomButtonGroupAsArrows from '../component/page_component/Destination/CustomButtonGroupArrows'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import WallpaperIcon from '@material-ui/icons/Wallpaper'
import PlayIcon from '@material-ui/icons/PlayCircleOutline'
import GatsbyLink from 'gatsby-link'
import CustomArrowBordered from '../component/page_component/Display/CustomArrowBordered'
import { TransitionProps } from '@material-ui/core/transitions'
import PropertyVideoDialog from '../component/page_component/PropertyVideoDialog'
import { SearchContext } from '../contexts/searchContext'

const useStyles = makeStyles((theme) => ({
  sliderDiv: {
    height: '90vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '70px 80px',
    [theme.breakpoints.between('md', 'lg')]: {
      gap: '2rem',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      gap: '1.5rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '0 1rem',
      paddingBlock: '20px 0',
      height: 'inherit',
      marginTop: '30px',
    },
  },

  heroSectionContainer: {
    display: 'flex',
    paddingTop: '40px',
  },
  imgDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70vh',
    overflow: 'hidden',
    // flexDirection: 'column',
    position: 'relative',
    borderRadius: '8px',
    [theme.breakpoints.between('xs', 'sm')]: {
      height: '100%',
    },
  },

  imgFullSize: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    // aspectRatio: 'calc(932/476)',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  backgroundImage: {
    width: '100%',
    minHeight: '650px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '8px',
    cursor: 'pointer',
    [theme.breakpoints.between('sm', 'md')]: {
      minHeight: '600px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      minHeight: '198px',
    },
  },
  backgroundImageDialog: {
    width: '100%',
    minHeight: '650px',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '8px',
    [theme.breakpoints.between('sm', 'md')]: {
      minHeight: '600px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      // minHeight: '198px',
    },
  },
  textDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: '2rem',
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingLeft: '0rem',
    },
  },
  itemClass: {
    display: 'flex',
    justifyContent: 'center',
  },
  carouselImg: {
    aspectRatio: 'calc(343/536)',
    maxWidth: '90%',
    margin: 'auto',
    objectFit: 'cover',
    display: 'block',
  },
  scollableImg: {
    // width: '100%',
    maxHeight: '80dvh',
    aspectRatio: 'calc(343/536)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '8px',
    [theme.breakpoints.between('sm', 'md')]: {
      minHeight: '600px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      minHeight: '198px',
    },
  },

  icon: {
    opacity: 0.5,
    width: '12rem',
    height: 'auto',
    [theme.breakpoints.between('md', 'md')]: {
      width: '8rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '4rem',
    },
  },
  contactContainer: {
    position: 'relative',
    background: `linear-gradient(to left,  #FAE9E5 68%, #FFF 2%)`,
    backgroundSize: '200% 100%', // Ajuste la largeur pour un meilleur contrôle
    backgroundPosition: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      background: `linear-gradient(to left,  #FAE9E5 60%, #FFF 2%)`,
    },
    [theme.breakpoints.down('sm')]: {
      boxShadow: '0px 1px 24px 0px rgba(0, 0, 0, 0.1)',
      marginInline: '16px',
      marginBottom: '80px',
      backgroundColor: '#fff',
    },
  },
  agentContactFormPosition: {
    position: 'absolute',
    top: '5%',
    left: '-42%',
    [theme.breakpoints.between('xs', 'sm')]: {
      position: 'unset',
      top: '0%',
      left: '0%',
    },
  },
  customDotList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    position: 'relative',
  },
  link: {
    textDecoration: 'none',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
  },
  backBlock: {
    width: '100%',
    marginBlock: '40px 20px',
  },
  backBtn: {
    textTransform: 'uppercase',
    fontFamily: `'Gotham Medium', serif`,
    fontWeight: 700,
  },
  country: {
    fontFamily: `'Gotham Light', serif`,
    textTransform: 'uppercase',
    fontWeight: 325,
    color: '#8A8A8A',
    fontSize: theme.typography.pxToRem(10),
  },
  preheader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.between('xs', 'sm')]: {
      // paddingTop: '24px',
    },
  },
  city: {
    fontFamily: `'Gotham Medium', serif`,
    fontWeight: 700,
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(18),
  },
  cityrow: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBlock: '4px 16px',
  },
  extraDetails: {
    paddingLeft: '80px',
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingLeft: '0px',
      marginBlock: '40px 0',
    },
  },
  carousselImg: {
    height: '100%',
  },
  btnGroup: {
    position: 'absolute',
    bottom: '24px',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    justifyContent: 'center',
    width: '100%', // Optionnel pour bien centrer
    [theme.breakpoints.between('md', 'lg')]: {
      bottom: '60px',
    },
    [theme.breakpoints.between('md', 'md')]: {
      bottom: '95px',
    },
  },
  paginationSlider: {
    flexGrow: 1,
    textAlign: 'center',
    fontFamily: `'Gotham Light', serif`,
    fontWeight: 325,
    fontSize: '14px',
    lineHeight: '16.8x',
  },

  actionBtn: {
    backgroundColor: '#fff',
    borderRadius: '8px',
  },
  divider: {},
  customDotImage: {
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
    },
  },
}))

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
}

const Property: React.FC<any> = (props) => {
  const { location, pageContext: data } = props

  const {
    id,
    autreVille,
    infos,
    isOffmarket,
    isProtected,
    lat,
    lng,
    nombreChambres,
    nombrePieces,
    photos,
    pk,
    prixAffichageFai,
    prixConfidentiel,
    prixLoyer,
    prixSaisonnierMax,
    prixSaisonnierMin,
    propertyTypeDisplay,
    reference,
    statutVente,
    suiviPar,
    surfaceHabitable,
    titreFr,
    unitArea,
    unitPrice,
    video,
    ville,
    codePostal,
    visiteVirtuelle,
    consommationEnergetique,
    propertyType,
    paysFr,
    paysEn,
    paysEs,
    paysPt,
    pays,
  } = data

  const srcSets = photos.map(({ large: src }: any, index: number) => ({ src, alt: index }))
  const srcVideoSets = video.map(({ filename: src }: any, index: number) => ({ src, alt: src }))
  const classes = useStyles()
  const intl = useIntl()
  const [sliderOpen, setSliderOpen] = useState(false)
  const [sliderVideoOpen, setSliderVideoOpen] = useState(false)
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const [currentSlide, setCurrentSlide] = useState(1)



  const handlePhotoDialogOpen = () => {
    setCurrentSlide(currentSlide)
    setSliderOpen(true)
  }

  const handlePhotoDialogClose = () => {
    setSliderOpen(false)
  }

  const cookieName = 'kretz-cookie-consent'
  const [cookieValue, setCookieValue] = useState(undefined)
  const title = data[`titre${capitalize(intl.locale)}`]
    ? data[`titre${capitalize(intl.locale)}`]
    : titreFr
      ? titreFr
      : infos
  const scrollRef = useRef()
  useEffect(() => {
    setCookieValue(getCookieValue(cookieName))
  }, [])



  const getHreflangLinks = (config, reference, type) => {
    const baseKey = '/fr/annonce'
    const baseLinks = config[baseKey] || {}

    const hreflangLinks = {}

    Object.keys(baseLinks).forEach((lang) => {
      hreflangLinks[lang] = `${baseLinks[lang]}/${reference.toLowerCase()}/${type.toLowerCase()}/`
    })

    return hreflangLinks
  }

  const hreflangLinks = getHreflangLinks(hreflangConfig, reference, propertyType)
  const metaDescription = `${propertyTypeDisplay} à vendre à ${ville} - Kretz, disponible sur Kretz, agence immobilière de luxe. Découvrez ce bien d'exception. Contactez-nous maintenant !`

  const HOME_PAGE_QUERY = graphql`
    query HomePageQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
      allProperty(
        filter: { isOffmarket: { eq: false }, statutVente: { eq: "Vente" } }
        sort: { fields: prixLoyer, order: ASC }
      ) {
        nodes {
          id
          ascenseur
          alarme
          airConditionne
          piscine
          bienEnCopro
          charges

          consommationEnergetique
          consommationEnergetiqueLabel
          emissionsGes
          emissionGesLabel
          energyCostMin
          energyCostMax
          coproEnProcedure
          nombreLotsCopro

          honorairesVente

          isOffmarket

          lifeStyle
          surfaceJardin
          surfaceTerrasse

          suiviPar {
            photo {
              portraitSquare
            }
            phone
            name
            email
          }
          photos {
            large
          }

          codePostal
          createdAt
          departement
          region
          descriptionAr
          descriptionEn
          descriptionEs
          descriptionFr
          descriptionPt
          descriptionRu
          descriptionZh
          nombreChambres
          nombrePieces
          nombreSallesDeBain
          pays

          prixAffichageFai
          prixConfidentiel
          prixLoyer

          propertyType
          propertyTypeDisplay
          propertyTypeSlug
          reference

          surfaceHabitable
          titreAr
          titreEn
          titreEs
          titreFr
          titrePt
          titreRu
          titreZh
          unitArea
          unitPrice

          ville
          villeFr
          villeEn
          villePt
          villeEs

          statutVente
          lat
          lng
          propertyTypeSlug
        }
      }

      allPropertyDistinct: allProperty {
        distinct(field: propertyType)
      }
    }
  `
  const {
    site: { siteMetadata },
    allProperty: { nodes },
    allPropertyDistinct: { distinct },
  } = useStaticQuery(HOME_PAGE_QUERY)

  const handleSlideChange = useCallback((_prev, { currentSlide }) => {
    setCurrentSlide(currentSlide - 1) // Mise à jour sans re-render du Dialog
  }, [])

  let bienSimilaire = nodes
    .filter(
      (property: any) =>
        property.codePostal === codePostal &&
        property.propertyType === propertyType &&
        property.id !== id,
    )
    .slice(0, 10)

  // If we have less than 10 properties, add more from the second filter
  if (bienSimilaire.length < 10) {
    const additionalProperties = nodes
      .filter(
        (property: any) =>
          property.propertyType === propertyType &&
          property.id !== id &&
          !bienSimilaire.includes(property), // Ensure no duplicates
      )
      .slice(0, 10 - bienSimilaire.length) // Get only the needed number

    bienSimilaire = [...bienSimilaire, ...additionalProperties] // Merge results
  }

  const canonicalUrl = `${siteMetadata.siteUrl}${location.pathname}`

  return (
    <Layout
      location={location}
      showFlotingMenu={false}
      maxWidth={false}
      title={(title || propertyTypeDisplay) + ' - Kretz'}
    >
      <Helmet>
        {(isOffmarket || isProtected) && <meta name="robots" content="noindex, nofollow" />}
        {!isOffmarket && !isProtected && canonicalUrl && (
          <link rel="canonical" href={canonicalUrl} />
        )}
        {!isOffmarket &&
          !isProtected &&
          hreflangLinks &&
          Object.keys(hreflangLinks).map((lang) => {
            return (
              <link key={lang} rel="alternate" hrefLang={lang} href={hreflangLinks[lang]} />
            )
          })}

        {!isOffmarket && !isProtected && metaDescription && (
          <meta name="description" content={metaDescription} />

        )}

        {!isOffmarket && !isProtected && <title>{title || propertyType} - Kretz</title>}
      </Helmet>

      <div className={classes.sliderDiv}>
        <Grid container className={classes.heroSectionContainer}>
          <Box className={classes.backBlock}>
            <Button
              onClick={() => {
                navigate(-1)
                // After navigating back, add the hash manually after a short delay
              }}
              className={classes.backBtn}
            >
              <KeyboardBackspaceIcon style={{ paddingRight: '0.5rem' }} />{' '}
              {intl.formatMessage({ id: 'back' })}
            </Button>
          </Box>
          <Hidden mdUp>
            <Box className={classes.preheader}>
              <Grid item className={classes.country}>
                {pays}
              </Grid>
            </Box>
            <Grid container className={classes.cityrow}>
              <Box className={classes.city}>{ville}</Box>
              <span className={classes.country} style={{ fontSize: '12px' }}>
                REF : {reference}
              </span>
            </Grid>
          </Hidden>

          {/* Partie gauche : image */}
          <Grid item xs={12} md={8} className={classes.imgDiv}>
            <Box className={classes.imgFullSize}>
              <Carousel
                customLeftArrow={<CustomArrowBordered side="left" />}
                customRightArrow={<CustomArrowBordered side="right" />}
                responsive={responsive}
                additionalTransfrom={0}
                arrows={true}
                autoPlay={false}
                autoPlaySpeed={5000}
                focusOnSelect={false}
                infinite
                keyBoardControl
                renderButtonGroupOutside={false}
                containerClass={classes.carousselImg}
                afterChange={handleSlideChange}
                customTransition="transform 1ms linear"
                minimumTouchDrag={10}
                transitionDuration={1}
              >
                {srcSets.map((srcSet: { src: string | undefined; alt: string | undefined }) => (
                  <Box
                    onClick={() => handlePhotoDialogOpen()}
                    key={srcSet.alt + '_imglinksTextlinksTextCarouselImg'}
                    className={classes.backgroundImage}
                    style={{ backgroundImage: `url(${srcSet.src})` }}
                  />
                ))}
              </Carousel>
            </Box>

            <Box className={classes.btnGroup}>
              <ButtonGroup
                variant="contained"
                aria-label="Basic button group"
                style={{ boxShadow: 'none' }}
              >
                <Button
                  className={classes.actionBtn}
                  onClick={() => handlePhotoDialogOpen()}
                  disabled={srcSets.length > 0 ? false : true}
                >
                  <WallpaperIcon style={{ marginRight: '0.5rem' }} />
                  {isSmDown ? '' : 'Photos'}
                </Button>

                <Button
                  className={classes.actionBtn}
                  onClick={() => setSliderVideoOpen(true)}
                  disabled={video?.length > 0 ? false : true}
                >
                  <PlayIcon style={{ marginRight: '0.5rem' }} />
                  {isSmDown ? '' : 'video'}
                </Button>
              </ButtonGroup>
            </Box>
          </Grid>

          <Grid item xs={12} md={4} className={classes.textDiv}>
            <PropertyBoxAnnonce
              id={id}
              paysFr={pays}
              titreFr={titreFr}
              confidentialPrice={prixConfidentiel}
              title={intl.formatMessage({
                id: `propretyType.${propertyTypeDisplay?.toLowerCase().trim()}`,
              })}
              unitPrice={unitPrice}
              price={
                STATUS_A_LOUER.includes(statutVente)
                  ? prixLoyer
                  : STATUS_SAISONNIER.includes(statutVente)
                    ? prixSaisonnierMin
                    : prixAffichageFai
              }
              priceMax={STATUS_SAISONNIER.includes(statutVente) ? prixSaisonnierMax : null}
              unitArea={unitArea}
              area={`${surfaceHabitable} ${unitArea == 'M2'
                ? intl.formatMessage({ id: 'squareMeter' })
                : intl.formatMessage({ id: 'squareMeter' })
                }`}
              ville={autreVille || ville}
              reference={reference}
              size={`${nombreChambres} ${intl.formatMessage({
                id: 'chamber' + (nombreChambres && nombreChambres > 1 ? 's' : ''),
              })}`}
              pieces={`${nombrePieces} ${intl.formatMessage({
                id: 'piece' + (nombrePieces && nombrePieces > 1 ? 's' : ''),
              })}`}
              isRented={STATUS_A_LOUER.includes(statutVente)}
              isSeasonal={STATUS_SAISONNIER.includes(statutVente)}
            />
          </Grid>
        </Grid>
      </div>

      {sliderOpen && (
        <PropertyDialog
          open={sliderOpen}
          images={srcSets}
          onClose={() => handlePhotoDialogClose()}
          initialSlide={currentSlide}
        />
      )}
      {sliderVideoOpen && (
        <PropertyVideoDialog
          open={sliderVideoOpen}
          images={srcVideoSets}
          onClose={() => setSliderVideoOpen(false)}
        />
      )}

      <Grid container md={12} className={classes.extraDetails}>
        <Grid item md={7} lg={7}>
          <PropertyExtraDetails data={data} />
        </Grid>
        <Grid item md={2} lg={2}></Grid>
        <Hidden smDown>
          <Grid item md={3} lg={3} xs={12} className={classes.contactContainer}>
            {suiviPar && (
              <Box className={classes.agentContactFormPosition}>
                <AgentContactForm
                  reference={reference}
                  emailTo={suiviPar && suiviPar.email}
                  agent={suiviPar}
                  country={pays}
                />
              </Box>
            )}
          </Grid>
        </Hidden>
      </Grid>

      {/* {suiviPar && <Agent agent={suiviPar} />} */}
      {/* <Box className={classes.divider} /> */}
      <Hidden smUp>
        <hr
          style={{
            width: '90%',
            backgroundColor: '#EDEDED',
            opacity: '0.3',
            marginInline: 'auto',
            marginBlock: '24px',
          }}
        />
      </Hidden>

      <Box className={classes.divider} />
      <PropertyMap
        lat={!lat ? Number.parseFloat('48.8566') : Number.parseFloat(lat)}
        lng={!lng ? Number.parseFloat('2.3522') : Number.parseFloat(lng)}
        isSmDown={isSmDown}
      />
      <Hidden smUp>
        <Grid xs={12} className={classes.contactContainer}>
          {suiviPar && (
            <Box className={classes.agentContactFormPosition}>
              <AgentContactForm
                reference={reference}
                country={pays}
                emailTo={suiviPar && suiviPar.email}
                agent={suiviPar}
              />
            </Box>
          )}
        </Grid>
      </Hidden>
      <PropertySlider />
      <Box></Box>
      <Display
        title={intl.formatMessage({ id: "new.Détail d'un bien_32" })}
        displayCTA={true}
        edges={bienSimilaire}
      />
      {/* <Display title={intl.formatMessage({ id: 'similarProperties' })} edges={edges} /> */}
      {/* <PropertyGallery srcs={srcSets} /> */}
      {/* <PropertyFooter /> */}
      {/* <SeoContent /> */}
      {/* <PropertyBottomBar pk={pk} reference={reference} title={title} isOffmarket={isOffmarket} /> */}
    </Layout>
  )
}

export default Property
