import React from 'react'
import { Box } from '@material-ui/core'

interface EgsProps {
  level: string
  size?: 'sm' | 'lg'
}

const Egs: React.FC<EgsProps> = ({ level, size = 'sm' }) => {
  const colors = {
    A: '#7AD7F4',
    B: '#68AECE',
    C: '#5E89A3',
    D: '#4F6585',
    E: '#474768',
    F: '#322C44',
    G: '#322C44',
  }

  // Liste des niveaux
  const levels = Object.keys(colors) as Array<keyof typeof colors>

  const isLarge = size === 'lg'
  const boxSize = isLarge ? '50px' : '20px'
  const fontSize = isLarge ? '1.2rem' : '0.8rem'
  const borderRadius = isLarge ? '4px' : '0px'
  const gap = isLarge ? '8px' : '0px'

  return (
    <div style={{ display: 'flex', gap }}>
      {levels.map((lvl) => (
        <Box
          key={lvl}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: colors[lvl],
            color: '#FFF',
            height: boxSize,
            width: boxSize,
            borderRadius: borderRadius,
            fontWeight: 'bold',
            fontSize: fontSize,
            textAlign: 'center',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            transform: lvl === level ? 'scale(1.3)' : 'scale(1)',
            transition: 'transform 0.2s ease',
            zIndex: lvl === level ? 2 : 1,
            marginInline: lvl === level ? '5px' : '',
          }}
        >
          {lvl === level ? lvl : ''}
        </Box>
      ))}
    </div>
  )
}

export default Egs
