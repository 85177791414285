import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import axios from 'axios'
import './Player.css'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

interface VideoPlayerProps {
  urls: string
  controls?: boolean
}

const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  const { urls, controls = true, ...rest } = props
  if (!urls) return <></>

  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))
  const [videoProps, setData] = useState({ height: 16, width: 9, format: 'vertical' })

  const getVideoSize = () => {
    if (!isSm && videoProps.format === 'horizontal') {
      return { width: '80vw', height: 'auto' }
    } else if (isSm && videoProps.format === 'horizontal') {
      return { width: '100vw', height: 'auto' }
    } else {
      return { width: `${(window.innerHeight * 9) / 20}px`, height: '80vh' }
    }
  }

  useEffect(() => {
    let isMounted = true
    axios
      .get(`https://vimeo.com/api/oembed.json?url=${urls}`, { params: { format: 'json' } })
      .then((response) => {
        if (isMounted) {
          setData({
            ...response.data,
            format: response.data.width / response.data.height > 1 ? 'horizontal' : 'vertical',
          })
        }
      })
      .catch((error) => {
        console.log('error viemo', error)
      })
    return () => {
      isMounted = false
    }
  }, [urls])

  return (
    <div className={`player-wrapper-${videoProps.format}`}>
      <ReactPlayer
        url={urls}
        controls={controls}
        width={getVideoSize().width}
        height={getVideoSize().height}
        config={{
          vimeo: {
            playerOptions: {
              responsive: 1,
              playsinline: 1,
            },
          },
          file: {
            attributes: {
              controlsList: 'nodownload',
              playsinline: 1,
            },
          },
        }}
        {...rest}
      />
    </div>
  )
}

export default VideoPlayer
