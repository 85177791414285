import { Box, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  imgStyle: {
    width: '50px',
    height: '50px',
    objectFit: 'cover',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '5px',
    transition: 'border 0.3s ease',
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '2px',
    },
  },
}))

interface CustomDotProps {
  images: { src: string; alt?: string }[]
  index?: number
  active?: boolean
  onClick?: () => void
}

const CustomDotImage: React.FC<CustomDotProps> = ({ images, index, active, onClick }) => {
  const classes = useStyles()
  const srcSet = images[index]

  return (
    <Box>
      <img
        src={srcSet?.src}
        alt={srcSet?.alt || `Slide ${index + 1}`}
        onClick={onClick}
        className={classes.imgStyle}
        style={{
          border: active ? '2px solid #1D1D1B' : '2px solid transparent',
        }}
      />
    </Box>
  )
}

export default CustomDotImage
