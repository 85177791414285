import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { getDisplayedPrice, numberWithSpacesAndCurrency } from '../../localization/number-formatter'
import { CustomButton } from '../CustomButton'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { Box, Button, Hidden, IconButton, Typography } from '@material-ui/core'
import Dpe from '../DPE & EGS/Dpe'
import Egs from '../DPE & EGS/Egs'
import { generatePdf } from '../../utils/submitForm'
import CustomIcon from '../shared/icons/CustomIcon'

const Line = styled.div`
  border-bottom: 0.7px solid gray;
  width: 1.5rem;
  margin: 0 0.5rem;
  height: 0.8em;
`

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#fff',
    opacity: 0.9,
    maxWidth: '620px',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  preheader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  head: {
    fontSize: '0.8rem',
    fontFamily: `'Gotham Book', serif`,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  type: {
    fontFamily: `'Gotham Light', serif`,
    fontSize: '12px',
    fontWeight: 325,
    lineHeight: '14.4px',
    textTransform: 'uppercase',
  },
  title: {
    lineHeight: '44px',
    fontSize: '40px',
    fontWeight: 400,
    fontFamily: `'Cormorant Garamond', serif`,
    paddingBlock: '8px 24px',
    color: '#1D1D1B',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '32px',
      paddingBlock: '8px 16px',
    },
  },
  specific: {
    fontSize: '0.9rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '24px',
    },
  },
  specificDetails: {
    flexWrap: 'nowrap',
    lineHeight: '14.4px',
    fontFamily: `'Gotham Light', serif`,
    alignItems: 'baseline',
    fontSize: '12px',
    fontWeight: 325,
    [theme.breakpoints.down('sm')]: {
      lineHeight: '16.8px',
    },
  },
  price: {
    fontFamily: `'Gotham Medium', serif`,
    marginTop: '5rem',
    width: '100%',
    textAlign: 'left',
    fontSize: '24px',
    whiteSpace: 'nowrap',
    lineHeight: '26.52px',
    fontWeight: 700,
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '18px',
      lineHeight: '19.89px',
      marginTop: '27px',
    },
  },
  city: {
    fontFamily: `'Gotham Medium', serif`,
    fontWeight: 700,
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(18),
  },
  country: {
    fontFamily: `'Gotham Light', serif`,
    textTransform: 'uppercase',
    fontWeight: 325,
    color: '#8A8A8A',
    fontSize: theme.typography.pxToRem(10),
  },
  btn: {
    backgroundColor: 'transparent',
    border: 'unset',
    cursor: 'pointer',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btnTxt: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontFamily: "'Gotham Book', serif",
    fontWeight: 700,
    fontSize: '12px',
  },
  downloadBtn: {
    height: '32px', // Ajuste la taille selon ton besoin
    width: '32px',
    minWidth: '32px', // Empêche le bouton de s'étendre
    backgroundColor: '#fff',
    border: '1px solid black',
    borderRadius: '4px', // Optionnel pour un léger arrondi
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0, // Évite tout espace supplémentaire
    '&:hover': {
      backgroundColor: '#f5f5f5', // Effet léger au hover
    },
  },
}))

interface PropertyBoxAnnonceProps {
  id: number
  area: string
  confidentialPrice: boolean
  isRented: boolean
  isSeasonal: boolean
  pieces: string
  price: string
  priceMax: string
  reference: string
  size: string
  title: string
  unitArea: string
  unitPrice: string
  ville: string
  villeFr?: string
  villeEn?: string
  villeEs?: string
  villePt?: string
  paysFr?: string
  paysEn?: string
  paysEs?: string
  paysPt?: string
  titreFr?: string
}

const getCityName = (props: PropertyBoxAnnonceProps, locale: string) => {
  const { ville, villeFr, villeEn, villeEs, villePt } = props
  switch (locale) {
    case 'fr':
      return villeFr || ville
    case 'es':
      return villeEs || ville
    case 'en':
      return villeEn || ville
    case 'pt':
      return villePt || ville
    default:
      return ville
  }
}

export const PropertyBoxAnnonce: React.FC<PropertyBoxAnnonceProps> = (props) => {
  const {
    id,
    title,
    titreFr,
    price,
    reference,
    area,
    size,
    pieces,
    confidentialPrice,
    isRented,
    unitPrice,
    isSeasonal,
    priceMax,
    unitArea,
    paysFr,
    paysEn,
    paysEs,
    paysPt,
  } = props
  const classes = useStyles()
  const intl = useIntl()

  const ville = getCityName(props, intl.locale)

  return (
    <Grid container className={classes.container}>
      <Grid container className={classes.head}>
        <Box className={classes.preheader}>
          <Grid item className={classes.country}>
            {(() => {
              switch (intl.locale) {
                case 'en':
                  return paysFr
                case 'es':
                  return paysFr
                case 'fr':
                  return paysFr
                case 'pt':
                  return paysFr
                default:
                  return paysFr
              }
            })()}
          </Grid>
          <Grid item className={classes.country}>
            REF : {reference}
          </Grid>
        </Box>
        <Box className={classes.city}>{ville}</Box>
      </Grid>
      <Grid container className={clsx(classes.specific)} justifyContent="flex-start">
        <Grid item className={classes.type} container>
          {title}
        </Grid>
        <Grid item className={classes.title} container>
          {titreFr}
        </Grid>
        <Grid item className={classes.specificDetails} container>
          <div>{`${area} `}</div>
          <Line />
          <div>{` ${size} `}</div>
          <Line />
          <div>{` ${pieces}`}</div>
        </Grid>
        <div className={clsx(classes.price)}>
          {getDisplayedPrice(
            price,
            priceMax,
            unitPrice,
            confidentialPrice,
            intl.formatMessage({ id: 'confidentialPrice' }),
            isRented,
            isSeasonal,
            intl.formatMessage({ id: 'perMonths' }),
            intl.formatMessage({ id: 'perWeeks' }),
            intl.formatMessage({ id: 'fromshort' }),
            intl.formatMessage({ id: 'toshort' }),
            false,
          )}
          {/* <Hidden mdUp>
            <Button className={classes.downloadBtn} onClick={() => generatePdf(id, reference)}>
              <CustomIcon src="toDownloadIcon.png" height={15} width={12} />
            </Button>
          </Hidden> */}
        </div>
      </Grid>
      <Hidden smDown>
        <Grid
          container
          className={clsx(classes.specific)}
          justifyContent="flex-end"
          style={{ visibility: 'hidden' }}
        >
          <Grid item className={classes.btnContainer} container>
            <button className={classes.btn} onClick={() => generatePdf(id, reference)}>
              <Typography className={classes.btnTxt}>
                {intl.formatMessage({ id: "new.Détail d'un bien_7" })}&nbsp;&nbsp;
                <CustomIcon src={`toDownloadIcon.png`} height={15} width={12} />
              </Typography>
            </button>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  )
}
