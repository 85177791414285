import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import CloseIcon from '@material-ui/icons/Close'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Box,
  makeStyles,
  Hidden,
} from '@material-ui/core'
import CustomButtonGroupAsArrows from './Destination/CustomButtonGroupArrows'
import CustomDotImage from '../shared/components/CustomDotImage'

const useStyles = makeStyles((theme) => ({
  paginationSlider: {
    flexGrow: 1,
    textAlign: 'center',
    fontFamily: `'Gotham Light', serif`,
    fontWeight: 325,
    fontSize: '14px',
    lineHeight: '16.8x',
  },
  eachImagePosition: {
    height: '70dvh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginInline: '7vw',
    [theme.breakpoints.between('xs', 'sm')]: {
      touchAction: 'pan-x',
      marginInline: '0vw',
      width: '100%',
      height: 'auto',
      maxHeight: '60dvh',
      // alignContent: 'center',
      minHeight: '60dvh',
    },
  },
  dotListClass: {
    bottom: '7vh!important',
    marginInline: '2rem',
    overflow: 'auto',
    justifyContent: 'unset !important',
    [theme.breakpoints.between('xs', 'sm')]: {
      bottom: 'unset!important',
      // position: 'unset!important',
      // marginTop: '5vh !important',
    },
  },
  itemClass: {
    [theme.breakpoints.between('xs', 'sm')]: {
      alignContent: 'center',
      maxHeight: '70vh',
    },
  },
}))

interface PropertyDialogProps {
  open: boolean
  images: { src: string; alt: string }[]
  onClose: () => void
  initialSlide?: number
}

const responsive = {
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
}

const PropertyDialog: React.FC<PropertyDialogProps> = ({
  open,
  images,
  onClose,
  initialSlide = 1,
}) => {
  const classes = useStyles()
  const [currentSlide, setCurrentSlide] = useState(1)
  const carouselRef = useRef<Carousel | null>(null)

  useEffect(() => {
    if (images.length === 1) {
      setCurrentSlide(1) // Afficher la seule image correctement
      return
    }

    setTimeout(() => {
      carouselRef.current?.goToSlide(initialSlide + 1)
    }, 200)
  }, [initialSlide])

  const handleCurrentSlide = (newIndex: number) => {
    setCurrentSlide(newIndex)
  }

  return (
    <Dialog
      disableEnforceFocus
      fullScreen
      fullWidth
      open={open}
      aria-labelledby="property-dialog"
      onClose={onClose}
    >
      {/* Header avec bouton de fermeture */}
      <DialogTitle
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <span className={classes.paginationSlider}>
          {images.length == 1 ? 1 : currentSlide - 1} / {images.length}
        </span>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: 'absolute', right: 10 }}
        >
          <CloseIcon style={{ fill: '#000' }} />
        </IconButton>
      </DialogTitle>

      {/* Contenu avec le carousel */}
      <DialogContent style={{ padding: '0' }} className={classes.itemClass}>
        <Carousel
          ref={carouselRef}
          swipeable={true}
          responsive={responsive}
          additionalTransfrom={0}
          arrows={false}
          autoPlay={false}
          infinite={images.length > 1}
          showDots
          dotListClass={classes.dotListClass}
          keyBoardControl
          renderButtonGroupOutside={true}
          renderDotsOutside={true}
          customDot={<CustomDotImage images={images} />}
          customButtonGroup={<CustomButtonGroupAsArrows isVertical />}
          customTransition="transform 1ms linear"
          minimumTouchDrag={10}
          transitionDuration={1}
          afterChange={function (previousSlide, _ref) {
            var currentSlide = _ref.currentSlide
            _ref.onMove
            return handleCurrentSlide(currentSlide)
          }}
        >
          {images.map((image, index) => (
            <>
              <Hidden smDown>
                <Box
                  key={index}
                  className={classes.eachImagePosition}
                  style={{
                    backgroundImage: `url(${image.src})`,
                  }}
                />
              </Hidden>
              <Hidden smUp>
                <Box key={index} className={classes.eachImagePosition}>
                  <img
                    src={image.src}
                    alt={image.alt}
                    loading="lazy"
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'contain',
                      display: 'block',
                    }}
                  />
                </Box>
              </Hidden>
            </>
          ))}
        </Carousel>
      </DialogContent>
    </Dialog>
  )
}

export default PropertyDialog
