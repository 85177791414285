import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import { useIntl } from 'gatsby-plugin-intl'
import { GoogleMap, LoadScript, Circle } from '@react-google-maps/api'
import { gmapsStyle } from '../../utils/constants'

const mapStyles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '50vh',
    padding: '0rem 2rem',
    marginBottom: theme.spacing(10),
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: theme.spacing(2),
    },
  },
  heading: {
    fontWeight: 700,
    fontFamily: `'Gotham Medium', serif`,
    lineHeight: '26.52px',
    paddingBlock: '0rem 1rem',
    fontSize: theme.typography.pxToRem(24),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: theme.spacing(3),
      fontSize: theme.typography.pxToRem(24),
    },
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  container: {
    flexBasis: '100%',
    [theme.breakpoints.between('xs', 'sm')]: {
      // flexBasis: '90%',
    },
    [theme.breakpoints.between('md', 'md')]: {
      // flexBasis: '80%',
    },
  },
  extraTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  mapDiv: {
    width: '100%',
    height: theme.spacing(70),
  },
}))

interface PropertyMapProps {
  lat: number
  lng: number
  isSmDown: boolean
}

const containerStyle = {
  width: '100%',
  height: '35rem',
}

export const PropertyMap: React.FC<PropertyMapProps> = (props) => {
  const classes = useStyles()
  const { lat, lng, isSmDown } = props
  const intl = useIntl()

  const center = {
    lat: Number.parseFloat(lat.toFixed(3)),
    lng: Number.parseFloat(lng.toFixed(3)),
  }

  const options = {
    strokeColor: '#F10520',
    strokeOpacity: 0.5,
    strokeWeight: 1.2,
    fillColor: '#F10530',
    fillOpacity: 0.2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1,
  }

  return (
    <Grid className={classes.root} container>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.heading} container>
          {intl.formatMessage({ id: 'location' })}
        </Grid>
        <Grid item xs={12} className={classes.mapDiv}>
          <LoadScript googleMapsApiKey="AIzaSyAbHDxCpLwdkI0JZWYcV14-zVm6_dFAR5o">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={12}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
                styles: mapStyles,
                // styles: gmapsStyle,
              }}
            >
              <Circle center={center} radius={2100} options={options}></Circle>
            </GoogleMap>
          </LoadScript>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PropertyMap
