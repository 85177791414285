import React from 'react'
import styled from 'styled-components'
import ArrowBackIcon from '@material-ui/icons/KeyboardArrowLeft'
import ArrowForward from '@material-ui/icons/KeyboardArrowRight'
import { IconButton } from '@material-ui/core'

interface Props {
  side: 'left' | 'right'
  onClick?: () => void
}

const ArrowContainer = styled(IconButton)`
  background-color: white !important;
  color: black !important;
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #f0f0f0 !important;
  }

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props: Props) => (props.side === 'left' ? 'left: 10px' : 'right: 10px')};
`

const CustomArrowBordered: React.FC<Props> = ({ side, onClick }) => {
  return (
    <ArrowContainer side={side} onClick={onClick}>
      {side === 'left' ? <ArrowBackIcon fontSize="small" /> : <ArrowForward fontSize="small" />}
    </ArrowContainer>
  )
}

export default CustomArrowBordered
