type destinationType = {
  name: string
  translation: string
  img: string
  sliderImg: string
  alt: string
  type: string
  key: string
  btnTxt: string
}

export const STATUS_A_LOUER = ['ALO_A']
export const STATUS_SAISONNIER = ['SAI_A']

export const LIFESTYLE = {
  "appartment": { "image": "ComponentAppart.png", "mainImg": 'Appartement.jpg' },
  "villa": { "image": "ComponentVilla.png", "mainImg": 'Villa.jpg' },
  "chalet": { "image": "ComponentChalet.png", "mainImg": 'Chalet.jpg' },
  "hotelparticulier": { "image": "ComponentHouse.png", "mainImg": 'Hotel_Particulier.jpg' },
  "castle": { "image": "ComponentCastle.png", "mainImg": 'CHATEAU.jpg' },
  "loft": { "image": "ComponentLoft.png", "mainImg": 'LOFT.jpg' },
  "mas": { "image": "ComponentMas.png", "mainImg": 'masimage.jpg' },
  "house": { "image": "ComponentHouse.png", "mainImg": 'houseimage.jpg' }
}




export const DESTINATIONS: {
  [key: string]: destinationType
} = {
  'france': {
    name: 'paris',
    key: 'DDR_PARIS',
    translation: 'paris',
    img: 'france.jpg',
    sliderImg: 'DDR_Paris.jpg',
    alt: 'paris',
    type: 'Paris',
    btnTxt: 'ourHouseInParis',
  },

  'états unis': {
    name: 'usa',
    key: 'DDR_USA',
    translation: 'usa',
    img: 'usa.jpg',
    sliderImg: 'DDR_USA.jpg',
    alt: 'usa',
    type: 'USA',
    btnTxt: 'ourHouseInUS',
  },
  "monaco": {
    name: "monaco",
    key: "DDR_MONACO",
    translation: "monaco",
    img: "monaco.jpg",
    sliderImg: "monaco.jpg",
    alt: "monaco",
    type: "Monaco",
    btnTxt: "ourHouseInMonaco",
  },
  'espagne': {
    key: 'DDR_ESPAGNE',
    name: 'spain',
    translation: 'spain',
    img: 'espagne.jpg',
    sliderImg: 'DDR_Esp.jpg',
    alt: 'spain',
    type: 'Méditerranée',
    btnTxt: 'ourHouseInSpain',
  },
  'portugal': {
    name: 'portugal',
    key: 'DDR_PORTUGAL',
    translation: 'portugal',
    img: 'lisbonne.jpg',
    sliderImg: 'DDR_Port.jpg',
    alt: 'portugal',
    type: 'Portugal',
    btnTxt: 'ourHouseInPortugal',
  },
  'lisbonne': {
    name: 'portugal',
    key: 'DDR_PORTUGAL',
    translation: 'portugal',
    img: 'lisbonne.jpg',
    sliderImg: 'DDR_Port.jpg',
    alt: 'portugal',
    type: 'Portugal',
    btnTxt: 'ourHouseInPortugal',
  },
  "maroc": {
    name: "maroc",
    key: "DDR_PARIS",
    translation: "paris",
    img: "maroc.jpg",
    sliderImg: "maroc.jpg",
    alt: "maroc",
    type: "Maroc",
    btnTxt: "ourHouseInParis",
  },
  "saint barthélemy": {
    name: "saint barthélemy",
    key: "DDR_PARIS",
    translation: "paris",
    img: "saintbarth.jpg",
    sliderImg: "saintbarth.jpg",
    alt: "saint barthélemy",
    type: "saint barthélemy",
    btnTxt: "ourHouseInParis",
  },
  "brésil": {
    name: "brésil",
    key: "DDR_PARIS",
    translation: "brésil",
    img: "bresil.jpg",
    sliderImg: "bresil.jpg",
    alt: "brésil",
    type: "brésil",
    btnTxt: "ourHouseInParis",
  },
  "paris": {
    name: "paris",
    key: "DDR_PARIS",
    translation: "paris",
    img: "paris2.jpg",
    sliderImg: "DDR_Paris.jpg",
    alt: "paris",
    type: "Paris",
    btnTxt: "ourHouseInParis",
  },
  "aix": {
    name: "aix",
    key: "DDR_AIX",
    translation: "aix",
    img: "aix_main.jpg",
    sliderImg: "DDR_Aix.jpg",
    alt: "aix",
    type: "Aix",
    btnTxt: "ourHouseInAix",
  },
  "cannes": {
    name: "cannes",
    key: "DDR_CANNES",
    translation: "cannes",
    img: "cannes_main.jpg",
    sliderImg: "DDR_Cannes.jpg",
    alt: "cannes",
    type: "Cannes",
    btnTxt: "ourHouseInCannes",
  },
  "bordeaux": {
    name: "bordeaux",
    key: "DDR_BORDEAUX",
    translation: "bordeaux",
    img: "bordeaux_main.jpg",
    sliderImg: "DDR_Bordeaux.jpg",
    alt: "bordeaux",
    type: "Bordeaux",
    btnTxt: "ourHouseInBordeaux",
  },
  "marseille": {
    name: "marseille",
    key: "DDR_MARSEILLE",
    translation: "marseille",
    img: "marseille_main.jpg",
    sliderImg: "DDR_Marseille.jpg",
    alt: "marseille",
    type: "Marseille",
    btnTxt: "ourHouseInMarseille",
  },
  "lyon": {
    name: "lyon",
    key: "DDR_LYON",
    translation: "lyon",
    img: "lyon_main.jpg",
    sliderImg: "DDR_Lyon.jpg",
    alt: "lyon",
    type: "Lyon",
    btnTxt: "ourHouseInLyon",
  },
  "lille": {
    name: "lyon",
    key: "DDR_LYON",
    translation: "lyon",
    img: "lille.jpg",
    sliderImg: "DDR_Lyon.jpg",
    alt: "lyon",
    type: "Lyon",
    btnTxt: "ourHouseInLyon",
  },

  "toulouse": {
    name: "toulouse",
    key: "DDR_TOULOUSE",
    translation: "toulouse",
    img: "toulouse.jpg",
    sliderImg: "DDR_Toulouse.jpg",
    alt: "toulouse",
    type: "Toulouse",
    btnTxt: "ourHouseInToulouse",
  },
  "nice": {
    name: "lyon",
    key: "DDR_LYON",
    translation: "lyon",
    img: "nice.jpg",
    sliderImg: "DDR_Lyon.jpg",
    alt: "lyon",
    type: "Lyon",
    btnTxt: "ourHouseInLyon",
  },
  "st-tropez": {
    name: "st-tropez",
    key: "DDR_ST_TROPEZ",
    translation: "stTropez",
    img: "saint-tropez.jpg",
    sliderImg: "DDR_StTropez.jpg",
    alt: "st-tropez",
    type: "St-tropez",
    btnTxt: "ourHouseInStTropez",
  },
  "megève": {
    name: "megeve",
    key: "DDR_MEGEVE",
    translation: "megeve",
    img: "megeve.jpg",
    sliderImg: "DDR_Megeve.jpg",
    alt: "megeve",
    type: "Megève",
    btnTxt: "ourHouseInMegeve",
  },
  "montpellier": {
    name: "montpellier",
    key: "DDR_MONTPELLIER",
    translation: "montpellier",
    img: "montpellier.jpg",
    sliderImg: "DDR_Montpellier.jpg",
    alt: "montpellier",
    type: "Montpellier",
    btnTxt: "ourHouseInMontpellier",
  },
  "le touquet": {
    name: "le-touquet",
    key: "DDR_LE_TOUQUET",
    translation: "leTouquet",
    img: "le_touquet.jpg",
    sliderImg: "DDR_LeTouquet.jpg",
    alt: "le-touquet",
    type: "Le Touquet",
    btnTxt: "ourHouseInLeTouquet",
  },
  "amiens": {
    name: "amiens",
    key: "DDR_AMIENS",
    translation: "amiens",
    img: "amiens.jpg",
    sliderImg: "DDR_Amiens.jpg",
    alt: "amiens",
    type: "Amiens",
    btnTxt: "ourHouseInAmiens",
  },
  "rouen": {
    name: "rouen",
    key: "DDR_ROUEN",
    translation: "rouen",
    img: "rouen.jpg",
    sliderImg: "DDR_Rouen.jpg",
    alt: "rouen",
    type: "Rouen",
    btnTxt: "ourHouseInRouen",
  },
  "rennes": {
    name: "rennes",
    key: "DDR_RENNES",
    translation: "rennes",
    img: "rennes.jpg",
    sliderImg: "DDR_Rennes.jpg",
    alt: "rennes",
    type: "Rennes",
    btnTxt: "ourHouseInRennes",
  },
  "la baule": {
    name: "la-baule",
    key: "DDR_LA_BAULE",
    translation: "laBaule",
    img: "la_baule.jpg",
    sliderImg: "DDR_LaBaule.jpg",
    alt: "la-baule",
    type: "La Baule",
    btnTxt: "ourHouseInLaBaule",
  },
  "la rochelle": {
    name: "la-rochelle",
    key: "DDR_LA_ROCHELLE",
    translation: "laRochelle",
    img: "la_rochelle.jpg",
    sliderImg: "DDR_LaRochelle.jpg",
    alt: "la-rochelle",
    type: "La Rochelle",
    btnTxt: "ourHouseInLaRochelle",
  },
  "l'ile de ré": {
    name: "lile-de-re",
    key: "DDR_LILE_DE_RE",
    translation: "lileDeRe",
    img: "ile_de_re.jpg",
    sliderImg: "DDR_LileDeRe.jpg",
    alt: "lile-de-re",
    type: "L'ile de ré",
    btnTxt: "ourHouseInLileDeRe",
  },
  "biarritz": {
    name: "biarritz",
    key: "DDR_BIARRITZ",
    translation: "biarritz",
    img: "biarritz.jpg",
    sliderImg: "DDR_Biarritz.jpg",
    alt: "biarritz",
    type: "Biarritz",
  },

  'barcelone': {
    name: 'paris',
    key: 'DDR_PARIS',
    translation: 'paris',
    img: 'barcelone.jpg',
    sliderImg: 'DDR_Paris.jpg',
    alt: 'paris',
    type: 'Paris',
    btnTxt: 'ourHouseInParis',
  },
  'madrid': {
    name: 'paris',
    key: 'DDR_PARIS',
    translation: 'paris',
    img: 'madrid.jpg',
    sliderImg: 'DDR_Paris.jpg',
    alt: 'paris',
    type: 'Paris',
    btnTxt: 'ourHouseInParis',
  },
  'maresme': {
    name: 'paris',
    key: 'DDR_PARIS',
    translation: 'paris',
    img: 'maresme.jpg',
    sliderImg: 'DDR_Paris.jpg',
    alt: 'paris',
    type: 'Paris',
    btnTxt: 'ourHouseInParis',
  },
  'costa brava': {
    name: 'paris',
    key: 'DDR_PARIS',
    translation: 'paris',
    img: 'costabrava.jpg',
    sliderImg: 'DDR_Paris.jpg',
    alt: 'paris',
    type: 'Paris',
    btnTxt: 'ourHouseInParis',
  },
  'girona': {
    name: 'paris',
    key: 'DDR_PARIS',
    translation: 'paris',
    img: 'girona.jpg',
    sliderImg: 'DDR_Paris.jpg',
    alt: 'paris',
    type: 'Paris',
    btnTxt: 'ourHouseInParis',
  },
  'sitges': {
    name: 'paris',
    key: 'DDR_PARIS',
    translation: 'paris',
    img: 'stiges.jpg',
    sliderImg: 'DDR_Paris.jpg',
    alt: 'paris',
    type: 'Paris',
    btnTxt: 'ourHouseInParis',
  },
  'ibiza': {
    name: 'paris',
    key: 'DDR_PARIS',
    translation: 'paris',
    img: 'ibiza.jpg',
    sliderImg: 'DDR_Paris.jpg',
    alt: 'paris',
    type: 'Paris',
    btnTxt: 'ourHouseInParis',
  },
  'majorque': {
    name: 'paris',
    key: 'DDR_PARIS',
    translation: 'paris',
    img: 'mallorca.jpg',
    sliderImg: 'DDR_Paris.jpg',
    alt: 'paris',
    type: 'Paris',
    btnTxt: 'ourHouseInParis',
  },
  'costa del sol': {
    name: 'paris',
    key: 'DDR_PARIS',
    translation: 'paris',
    img: 'costadelsol.jpg',
    sliderImg: 'DDR_Paris.jpg',
    alt: 'paris',
    type: 'Paris',
    btnTxt: 'ourHouseInParis',
  },
  'minorque': {
    name: 'paris',
    key: 'DDR_PARIS',
    translation: 'paris',
    img: 'minorque.jpg',
    sliderImg: 'DDR_Paris.jpg',
    alt: 'paris',
    type: 'Paris',
    btnTxt: 'ourHouseInParis',
  },

}

type lifestyleType = {
  titleTranslation: string
  leftImg: string
  rightImg: string
  mainImg: string
}



type alertGarden = {
  value: string
  label: string
}

export const ALERT_GARDEN: Array<alertGarden> = [
  { value: 'true', label: 'gardenNeeded' },
  { value: 'false', label: 'gardenNotNeeded' }
]

type numberOfBedrooms = {
  value: string
  label: string
}

export const NUMBER_OF_BEDROOMS: Array<numberOfBedrooms> = [
  { value: '1+', label: '1' },
  { value: '2+', label: '2' },
  { value: '3+', label: '3' },
  { value: '4+', label: '4' },
  { value: '5+', label: '5' },
  { value: '6+', label: '6' }
]

type propertyType = {
  value: string
  label: string
}

export const PROPERTY_TYPE: Array<propertyType> = [
  {
    value: 'A_',
    label: 'A_',
  },
  {
    value: 'APP',
    label: 'APP',
  },
  {
    value: 'IMM',
    label: 'IMM',
  },
  {
    value: 'HOT',
    label: 'HOT',
  },
  {
    value: 'LOF',
    label: 'LOF',
  },
  {
    value: 'MAI',
    label: 'MAI',
  },
  {
    value: 'CHA',
    label: 'CHA',
  },
  {
    value: 'CHL',
    label: 'CHL',
  },
  {
    value: 'VIL',
    label: 'VIL',
  },
  {
    value: 'PAR',
    label: 'PAR',
  },
  {
    value: 'BUR',
    label: 'BUR',
  }
]

export const gmapsStyle = ([
  {
    "featureType": "administrative",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#444444"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.attraction",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "hue": "#007cff"
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "hue": "#00b6ff"
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "hue": "#0033ff"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "hue": "#007cff"
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#d7e8d6"
      },
      {
        "weight": "4.62"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.school",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -100
      },
      {
        "lightness": 45
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#dcdcdc"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#ebebeb"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "transit.station.airport",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.station.airport",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "transit.station.bus",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.station.rail",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.station.rail",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
        "color": "#90d7e7"
      },
      {
        "visibility": "on"
      }
    ]
  }
]);

