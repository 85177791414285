import axios from 'axios'

const FORM_URL = `${process.env.GATSBY_API_URL || ''}/api/forms/`

const PDF_URL = `${process.env.GATSBY_API_URL || ''}/api/download/pdf/`

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

export async function submitForm(values: any) {
  const params: { [bar: string]: string } = {}
  Object.keys(values).forEach((key) => {
    if (Array.isArray(values[key])) {
      return (params[key] = values[key].join(','))
    } else {
      return (params[key] = values[key])
    }
  })
  try {
   
    const request = await axios.get(FORM_URL, { params })

    if (request?.data === 'OK') {
      return true
    } else {
      return false
    }
  } catch(err) {
 
    return false
  }
}

export async function generatePdf(id: any, reference: string) {
  try {
    const request = await axios({
      url: PDF_URL,
      responseType: 'blob',
      method: 'GET',
      params: { id },
    })
    const url = window.URL.createObjectURL(new Blob([request.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `fiche-${reference}.pdf`)
    document.body.appendChild(link)
    link.click()
  } catch {
    return null
  }
}
