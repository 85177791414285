import React, { useState } from 'react'

import CloseIcon from '@material-ui/icons/Close'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Dialog, DialogTitle, IconButton, DialogContent, Box, makeStyles } from '@material-ui/core'
import CustomButtonGroupAsArrows from './Destination/CustomButtonGroupArrows'
import CustomDotImage from '../shared/components/CustomDotImage'
import VideoPlayer from '../VideoPlayer'
import ReactPlayer from 'react-player/vimeo'
import CustomDotVideo from '../shared/components/CustomDotVideo'

const useStyles = makeStyles((theme) => ({
  paginationSlider: {
    flexGrow: 1,
    textAlign: 'center',
    fontFamily: `'Gotham Light', serif`,
    fontWeight: 325,
    fontSize: '14px',
    lineHeight: '16.8x',
  },
  eachImagePosition: {
    height: '70dvh',
    backgroundSize: 'cover',
    marginInline: '7vw',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginInline: '0vw',
    },
  },
  dotListClass: {
    bottom: '3vh!important',
    marginInline: '2rem',
    overflowY: 'auto',
    [theme.breakpoints.between('xs', 'sm')]: {
      // position: 'unset!important',
      // justifyContent: 'unset !important',
      // marginTop: '5vh !important',
    },
  },
  content: {
    padding: 0,
    marginInline: '5rem',
    alignContent: 'center',
    marginBottom: '50px',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginInline: '0rem',
      paddingBottom: '14vh',
    },
  },
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

interface PropertyDialogProps {
  open: boolean
  images: { src: string; alt: string }[]
  onClose: () => void
}

const responsive = {
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
}

const PropertyVideoDialog: React.FC<PropertyDialogProps> = ({ open, images, onClose }) => {
  const classes = useStyles()
  const [currentSlide, setCurrentSlide] = useState(1)

  const handleCurrentSlide = (newIndex: number) => {
    setCurrentSlide(newIndex)
  }

  return (
    <Dialog
      disableEnforceFocus
      fullScreen
      fullWidth
      open={open}
      aria-labelledby="property-dialog"
      onClose={onClose}
    >
      {/* Header avec bouton de fermeture */}
      <DialogTitle
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <span className={classes.paginationSlider}>
          {currentSlide} / {images.length}
        </span>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: 'absolute', right: 10 }}
        >
          <CloseIcon style={{ fill: '#000' }} />
        </IconButton>
      </DialogTitle>

      {/* Contenu avec le carousel */}
      <DialogContent style={{ padding: '0' }} className={classes.content}>
        {/* <div style={{ maxWidth: "800px", margin: "auto" }}>
          <ReactPlayer
            url="https://vimeo.com/708143160" // Replace with your Vimeo video URL
            controls
            width="100%"
            height="450px"
          />
        </div> */}
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows={false}
          autoPlay={false}
          showDots
          dotListClass={classes.dotListClass}
          keyBoardControl
          renderButtonGroupOutside={true}
          renderDotsOutside={true}
          //customDot={<CustomDotVideo videos={images} />}
          itemClass={classes.videoContainer}
          customButtonGroup={images.length > 1 ? <CustomButtonGroupAsArrows isVertical /> : null}
          afterChange={function (previousSlide, _ref) {
            var currentSlide = _ref.currentSlide
            _ref.onMove
            return handleCurrentSlide(currentSlide)
          }}
        >
          {images.map((image, index) => (
            <Box key={index}>
              <VideoPlayer urls={image.src}></VideoPlayer>
            </Box>
          ))}
        </Carousel>
      </DialogContent>
    </Dialog>
  )
}

export default PropertyVideoDialog
